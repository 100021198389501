import Language from 'scripts/domain/models/language';

export const STORAGE_KEY = 'translation-selected-language';

export default class LocalLanguageStore {
  constructor(storage) {
    this.storage = storage;
  }

  get() {
    const json = this.storage.getItem(STORAGE_KEY);
    return json ? Language.fromJs(JSON.parse(json)) : null;
  }

  getProvider() {
    return { get: this.get.bind(this) };
  }

  set(language) {
    this.storage.setItem(STORAGE_KEY, JSON.stringify(language.toJs()));
  }

  remove() {
    this.language = null;
    this.storage.removeItem(STORAGE_KEY);
  }
}
