import React, { useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';

import BubbleContent from 'components/customer/conversation_history/conversation_items_v2/content/bubble_content';
import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import ConversationMessageAttachments from 'components/customer/conversation_history/conversation_items_v2/message/attachments/conversation_message_attachments';
import { getMessagingAttachmentUrl } from 'scripts/domain/services/attachments';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import ItemCommError from 'components/customer/conversation_history/conversation_items_v2/item_comm_error';
import ItemContent from 'components/customer/conversation_history/conversation_items_v2/item_content';
import ItemContext from 'components/customer/conversation_history/conversation_items_v2/item_context';
import ItemMetadata from 'components/customer/conversation_history/conversation_items_v2/item_metadata';
import { MessageChannelType, MessageStatus } from 'models/conversation_message.js';
import TwitterMetadataIcon from 'components/customer/conversation_history/conversation_items_v2/message/twitter_metadata_icon';
import WhatsAppMetadataIcon from 'components/customer/conversation_history/conversation_items_v2/message/whatsapp_metadata_icon';
import InstagramMetadataIcon from 'components/customer/conversation_history/conversation_items_v2/message/instagram_metadata_icon';
import MessageReaction from 'components/customer/conversation_history/conversation_items_v2/message/message_reaction';
import {
  MessageItemImage,
  MessageItemVideo,
} from 'components/customer/conversation_history/conversation_items_v2/message/message_item_asset';
import useConditionalTranslation from 'components/hooks/use_conditional_translation';

export default function MessageItem({
  friendlyCompanyAddress,
  friendlyCustomerAddress,
  hasReaction,
  isFirstInStack,
  isLastInStack,
  isStacked,
  item,
  orgId,
}) {
  const channelType = item.content.getChannelType();
  return (
    <ItemContent
      isFirstInStack={isFirstInStack}
      isLastInStack={isLastInStack}
      isStacked={isStacked}
      item={item}
      showIcon={!isStacked}
    >
      <ItemMetadata
        icon={getIcon(channelType)}
        item={item}
        showMetadata={!isStacked}
        status={getStatus(item)}
        text={getMetadataText(item)}
        tooltip={getTooltipText(item)}
      />
      <MessageContent hasReaction={hasReaction} item={item} orgId={orgId} />
      <ItemCommError item={item} />
    </ItemContent>
  );

  function getIcon(channel) {
    // Add future messaging channel icons here
    switch (channel) {
      case MessageChannelType.TWITTER:
        return <TwitterMetadataIcon />;
      case MessageChannelType.WHATSAPP:
        return <WhatsAppMetadataIcon />;
      case MessageChannelType.INSTAGRAM_DIRECT:
        return <InstagramMetadataIcon />;
      default:
        return null;
    }
  }

  function getStatus(messageItem) {
    let status = messageItem.content.getStatus();
    switch (status) {
      case MessageStatus.DELIVERED:
      case MessageStatus.SENT:
        return 'delivered';
      case MessageStatus.FAILED:
      case MessageStatus.UNDELIVERED:
      case MessageStatus.UNKNOWN:
        return 'delivery failed';
      case MessageStatus.NEW:
      case MessageStatus.SENDING:
        return 'sending';
      case MessageStatus.RECEIVED:
        return 'received';
      case MessageStatus.READ:
        return 'read';
      default:
        return undefined;
    }
  }

  function getMetadataText(messageItem) {
    switch (messageItem.initiator.type) {
      case Communicator.AGENT:
        return `replied via ${messageItem.content.getChannelName()}`;
      case Communicator.CUSTOMER:
        switch (messageItem.content.channel) {
          case MessageChannelType.INSTAGRAM_DIRECT:
            if (messageItem.content.getStoryMentionUrl()) {
              return `mentioned ${friendlyCompanyAddress} via Instagram Story`;
            }
            if (messageItem.content.getStoryReplyUrl()) {
              return `replied to ${friendlyCompanyAddress} Story`;
            }
            return `sent an Instagram Message`;
          default:
            return `sent a ${messageItem.content.getChannelName()}`;
        }
      default:
        return null;
    }
  }

  function getTooltipText() {
    if (!friendlyCustomerAddress || !friendlyCompanyAddress) {
      return null;
    }

    switch (item.initiator.type) {
      case Communicator.AGENT:
        return `sent from ${friendlyCompanyAddress} to ${friendlyCustomerAddress}`;
      case Communicator.CUSTOMER:
        return `sent from ${friendlyCustomerAddress} to ${friendlyCompanyAddress}`;
      default:
        return null;
    }
  }
}

MessageItem.propTypes = {
  friendlyCompanyAddress: PropTypes.string,
  friendlyCustomerAddress: PropTypes.string,
  hasReaction: PropTypes.bool,
  isFirstInStack: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  orgId: PropTypes.string.isRequired,
};

export function MessageContent({ item, hasReaction, orgId }) {
  const channelType = _.trim(item.content.getChannelType());
  const isAutoreply = item.initiator.type === Communicator.AUTOMATED;
  const isInstagram = channelType === MessageChannelType.INSTAGRAM_DIRECT;

  if (item.content.isVideoMessage()) {
    return renderVideo();
  }

  if (isInstagram) {
    if (item.content.isStoryImageMessage()) {
      return renderStoryImage();
    }
    if (item.content.isStoryVideoMessage()) {
      return renderVideo();
    }
  }

  return (
    <BubbleContent
      className={classnames('messageItem-content', _.camelCase(channelType))}
      data-aid={`messageItem-${item?.id}`}
      hasReaction={hasReaction}
      item={item}
    >
      {isAutoreply ? <div className="messageItem-autoreply">Autoreply</div> : null}
      <MessageText item={item} />
      {renderAttachments()}
      {renderReaction()}
    </BubbleContent>
  );

  function renderAttachments() {
    let itemAttachments = item.content.getAttachments();
    if (itemAttachments.length === 0) {
      return null;
    }
    return <ConversationMessageAttachments attachments={itemAttachments} />;
  }

  function renderReaction() {
    if (item.content.reactions && item.content.reactions.length > 0) {
      return <MessageReaction timestamp={item.content.reactions[0].timestamp} />;
    }
    return null;
  }

  function renderVideo() {
    const videos = item.content.getVideoAttachments();
    let url;
    switch (item.content.channel) {
      case MessageChannelType.INSTAGRAM_DIRECT:
        url =
          (videos.length === 1 && videos[0].source.path) ||
          item.content.getStoryMentionUrl() ||
          item.content.getStoryReplyUrl();
        break;

      case MessageChannelType.WHATSAPP:
        url = getMessagingAttachmentUrl(orgId, videos[0]);
        break;

      default:
        return null;
    }

    if (!url) {
      return null;
    }
    return <MessageItemVideo item={item} url={url} />;
  }

  function renderStoryImage() {
    const url = item.content.getStoryMentionUrl() || item.content.getStoryReplyUrl();
    return <MessageItemImage item={item} url={url} />;
  }
}

MessageContent.propTypes = {
  hasReaction: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  orgId: PropTypes.string.isRequired,
};

export function MessageText({ item }) {
  const { isViewOriginal } = useContext(ItemContext);
  const text = useConditionalTranslation(item, isViewOriginal);

  return (
    <div dir="auto">
      <InlineAnnotatedContentContainer
        hasRedactedPaymentCard={item.content.hasRedactedPaymentCardNumber()}
        id={item.id}
        isRedacted={item.content.isRedacted}
        text={text}
      />
    </div>
  );
}

MessageText.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
